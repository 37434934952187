var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login mt-6 pt-6"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-justify-content-center"},[_c('div',{staticClass:"column is-two-fifths-tablet"},[_vm._m(0),_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"py-4",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('vue-phone-number-input',{attrs:{"color":"#FF6600","valid-color":"#45BE7D","error-color":"#FF4060","default-country-code":"ID","placeholder":_vm.$t('user.phone')},on:{"update":_vm.handleInputPhoneNumber},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text","id":"fullname","placeholder":"Enter your Fullname"},model:{value:(_vm.user.fullname),callback:function ($$v) {_vm.$set(_vm.user, "fullname", $$v)},expression:"user.fullname"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"email","id":"email","placeholder":"Enter your Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"password","id":"password","placeholder":"Enter your Password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Password Confirmation","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"password","id":"password_confirmation","placeholder":"Repeat your Password"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})],1)]}}])}),_c('b-button',{staticClass:"mb-3",attrs:{"type":"is-primary is-gradient","expanded":"","native-type":"submit"}},[_vm._v("Register")]),_c('b-button',{attrs:{"type":"is-primary","expanded":"","outlined":"","to":"/login","tag":"router-link"}},[_vm._v("Login")])],1)])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"has-text-centered"},[_c('img',{staticClass:"login-logo mb-4",attrs:{"src":"/img/assets/logo.png","alt":"Sgara Logo"}}),_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("Welcome to Sgara")]),_c('h4',{staticClass:"is-size-6"},[_vm._v("Register your account by filling the form below")])])}]

export { render, staticRenderFns }