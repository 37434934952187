<template>
  <div class="login mt-6 pt-6">
    <div class="container">
      <div class="columns is-justify-content-center">
        <div class="column is-two-fifths-tablet">
          <header class="has-text-centered">
            <img
                class="login-logo mb-4"
                src="/img/assets/logo.png"
                alt="Sgara Logo">

            <h3 class="is-size-4 has-text-weight-bold">Welcome to Sgara</h3>
            <h4 class="is-size-6">Register your account by filling the form below</h4>
          </header>
          <ValidationObserver ref="form">
            <form @submit.prevent="register" class="py-4" autocomplete="off">
              <ValidationProvider name="Phone Number" rules="required" v-slot="{ errors }">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <vue-phone-number-input
                      color="#FF6600"
                      valid-color="#45BE7D"
                      error-color="#FF4060"
                      default-country-code="ID"
                      v-model="phone" @update="handleInputPhoneNumber" :placeholder="$t('user.phone')"/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Fullname" rules="required" v-slot="{ errors }">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="text" id="fullname" v-model="user.fullname"
                           placeholder="Enter your Fullname"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="email" id="email" v-model="user.email"
                           placeholder="Enter your Email"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Password" rules="required" v-slot="{ errors }" vid="password">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="password" id="password" v-model="user.password"
                           placeholder="Enter your Password"></b-input>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Password Confirmation" rules="required|confirmed:password" v-slot="{ errors }">
                <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input type="password" id="password_confirmation" v-model="user.password_confirmation"
                           placeholder="Repeat your Password"></b-input>
                </b-field>
              </ValidationProvider>

              <b-button type="is-primary is-gradient" expanded class="mb-3" native-type="submit">Register</b-button>
              <b-button type="is-primary" expanded outlined to="/login" tag="router-link">Login</b-button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data: () => {
    return {
      user: {},
      phone: null,
    }
  },
  methods: {
    handleInputPhoneNumber(payload) {
      this.user.phone = payload.e164;
    },

    async register() {
      this.$refs.form.validate().then(async success => {
        if (!success) return 0

        this.$loading()
        let res = await this.$store.dispatch('user/register', this.user)
        this.$loading(false)

        if (!res.error) return this.$router.push('/login')
      })
    }
  }
}
</script>
